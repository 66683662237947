$headerHeight: 125px;
$mobileHeaderHeight: 100px;

.header {
  @apply absolute z-40 w-full bg-white;

  & + main {
    padding-top: $headerHeight;
    @screen mdmax {
      padding-top: $mobileHeaderHeight;
    }
  }
}

.headerStuck {
  @apply fixed top-0 w-full z-40 shadow-lg bg-black bg-opacity-90;
}

.navContainer {
  @apply container mdmax:items-center flex justify-between items-center;
  height: $headerHeight;
  @screen mdmax {
    height: $mobileHeaderHeight;
  }
}

.nav {
  @apply flex flex-1 gap-x-4 items-center justify-end mdmax:w-full mdmax:items-center;
}

.ul {
  @apply flex flex-wrap items-center my-0 no-prose-list transition-all;

  @screen mdmax {
    @apply flex-col absolute top-full left-0 bg-gray-200 w-full shadow-2xl;
  }
  & ul {
    @apply mdmax:w-full;
  }
  & li {
    @apply mb-0 mdmax:w-full;
    @screen mdmax {
      @apply border-b border-white last:border-0;
    }
  }
}

.ulOpen {
  @apply mdmax:opacity-100 mdmax:visible;
}

.ulClosed {
  @apply mdmax:opacity-0 mdmax:invisible;
}

.logo {
}

.hamburger {
  @apply lgmin:hidden font-normal flex items-center justify-center relative cursor-pointer ml-3 transition duration-300 z-50;
  width: 45px;
  height: 65px;
  & span {
    &:not(:nth-of-type(4)) {
      @apply bg-black absolute left-0 transition-transform w-full;
      height: 1.5px;
    }
    &:nth-of-type(1) {
      transform: translateY(-1em) rotate(0deg);
    }
    &:nth-of-type(2) {
      transform: translateY(-0.4em);
      opacity: 1;
    }
    &:nth-of-type(3) {
      transform: translateY(0.3em) rotate(0deg);
    }
    &:nth-of-type(4) {
      @apply absolute bottom-1 left-1/2 -translate-x-1/2 text-xs text-center pointer-events-none select-none;
    }
  }
}

.hamburgerOpen {
  & span {
    &:nth-of-type(1) {
      transform: translateY(-0.5em) rotate(-45deg);
    }
    &:nth-of-type(2) {
      opacity: 0;
    }
    &:nth-of-type(3) {
      transform: translateY(-0.5em) rotate(45deg);
    }
  }
}

.hamburgerStuck {
  & span {
    &:not(:nth-of-type(4)) {
      @apply bg-white;
    }
    &:nth-of-type(4) {
      @apply text-white;
    }
  }
}
