.input {
  @apply block relative w-full appearance-none focus:outline-none bg-transparent disabled:opacity-50 focus:disabled:cursor-not-allowed focus:disabled:pointer-events-none font-normal;

  // https://notiz.dev/blog/floating-form-field-with-tailwindcss
  // Key to the floating span is the following + placeholder=" "
  &:not(:placeholder-shown) ~ label {
    @apply hidden;
  }
  // &:focus-within {
  //   & ~ label {
  //     @apply transform scale-75 -translate-y-[65%] text-tertiary;
  //   }
  // }
}

.input-light {
  @apply text-white;
}

.input-dark {
}

.input-admin {
}

.input-md {
}

.input-sm {
  @apply text-sm;
}

.inputWrapper {
  @apply rounded-lg relative text-left;
}

.inputWrapper-light {
  @apply text-white border-gray-100 focus:border-primary border-2;
}

.inputWrapper-dark {
  @apply border-gray-200 focus:border-primary border-2;
}

.inputWrapper-admin {
  @apply bg-white border-black border-2 text-black;
}

.inputWrapper-md {
  @apply p-2 my-2;
}

.inputWrapper-sm {
  @apply p-1 my-1 text-sm;
}

.placeholder {
  @apply text-gray-400 font-normal absolute pointer-events-none duration-300 origin-left;
}

.placeholder-light {
  @apply text-white;
}

.placeholder-dark {
}

.placeholder-admin {
  @apply text-gray-600;
}

.placeholder-md {
  @apply top-2;
}

.placeholder-sm {
  @apply top-1 text-sm;
}

.checkboxes {
  @apply flex flex-wrap;
}

.checkbox {
  @apply flex items-center mr-3 mb-1;
  & input {
    @apply mr-2;
  }
}

.radios {
  @apply flex flex-wrap;
}

.radio {
  @apply flex items-center mr-3 mb-1;
  & input {
    @apply mr-1;
  }
}

.select {
  @apply pr-4;
}

.switch {
  @apply inline-flex relative items-center cursor-pointer;
  & input {
    @apply sr-only;
  }
  & label {
    @apply ml-2 text-sm;
  }
  & div {
    @apply relative w-[65px] h-[35px] bg-gray-200 rounded-full;
    &:after {
      content: "";
      @apply absolute top-[2.5px] left-[2.5px] bg-white border border-gray-300 rounded-full w-[30px] h-[30px] transition-all;
    }
  }
}

.switchChecked {
  & input {
    & + div {
      @apply after:translate-x-full bg-primary;
    }
  }
}

.errorMessage {
  @apply text-error mb-2 text-sm font-normal;
}

.successMessage {
  @apply text-success mb-2 text-sm font-normal;
}

.requiredAsterix {
  @apply text-error ml-1;
}
