.btn {
  @apply text-center border-2 inline-block font-bold transition-colors ease-in-out duration-500 cursor-pointer outline-none hover:outline-none focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none whitespace-nowrap rounded-3xl;
  &:not([class*="outline"]) {
    @apply hover:bg-opacity-75 hover:border-opacity-0 focus:bg-opacity-75 focus:border-opacity-0;
  }
}
.sm {
  @apply text-sm py-[0.8rem] px-[1.2rem] leading-3;
}
.md {
  @apply text-base p-3 leading-4;
}
.lg {
  @apply text-lg p-4;
}
.primary {
  @apply bg-primary border-primary text-white hover:text-white;
}
.secondary {
  @apply bg-secondary border-secondary text-white hover:text-white;
}
.white {
  @apply bg-white border-white text-black hover:text-black;
}
.black {
  @apply bg-black border-black text-white hover:text-white;
}
.gray-100 {
  @apply bg-gray-100 border-gray-100 text-gray-800 hover:text-gray-800;
}
.gray-200 {
  @apply bg-gray-200 border-gray-200 text-gray-800 hover:text-gray-800;
}
.gray-300 {
  @apply bg-gray-300 border-gray-300 text-gray-800 hover:text-gray-800;
}
.gray-400 {
  @apply bg-gray-400 border-gray-400 text-gray-800 hover:text-gray-800;
}
.outlinePrimary {
  @apply border-primary hover:bg-primary hover:text-white;
}
.outlineSecondary {
  @apply border-secondary hover:bg-secondary hover:text-white;
}
.outlineWhite {
  @apply border-white hover:bg-white hover:text-black;
}
.outlineBlack {
  @apply border-black hover:bg-black hover:text-white;
}
.btnLinkStyle {
}
.error {
  @apply bg-error border-error hover:bg-error-dark text-white hover:text-white;
}
.success {
  @apply bg-success border-success hover:bg-success-dark text-white hover:text-white;
}
.warning {
  @apply bg-warning border-warning hover:bg-warning-dark text-white hover:text-white;
}
.outlineError {
  @apply border-error hover:bg-error hover:text-white;
}
.outlineSuccess {
  @apply border-success hover:bg-success hover:text-white;
}
.outlineWarning {
  @apply border-warning hover:bg-warning hover:text-white;
}
